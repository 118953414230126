body {
  background-image: url("./assets/background.png");
  background-repeat: repeat-y;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Raleway";
  src: url("./fonts/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Comfortaa";
  src: url("./fonts/Comfortaa-VariableFont_wght.ttf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

form {
  font-weight: bold;
}

input[type="text"],
select,
textarea {
  font-family: "Montserrat" !important;
}

.input-style {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.scrollableMenu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

.page-title {
  font-family: "Raleway" !important;
}

.items-shown {
  font-family: "Comfortaa";
}

input::placeholder {
  font-family: "Comfortaa";
}

/* Button */
.btn {
  font-family: "Raleway";
}
/* /Button */

/* Pagination */
.page-link {
  color: #212529 !important;
}

.page-item.active .page-link {
  background-color: rgba(196, 232, 249, 0.4) !important;
  border-color: rgba(196, 232, 249, 0.4) !important;
  color: #212529 !important;
}
/* /Pagination */

/* Table */
.table {
  background-color: rgba(196, 232, 249, 0.4);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68);
  -moz-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68);
  box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68);
}

.table thead th {
  background-color: rgba(196, 232, 249, 1) !important;
  border-bottom: 2px solid #212529 !important;
  color: #212529 !important;
}

.table td {
  vertical-align: middle !important;
  border-top: 1px solid rgba(108, 117, 125, 0.3) !important;
}

.table tbody tr,
.table tbody tr a {
  color: #212529 !important;
}

.table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.5) !important;
  color: #212529 !important;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(108, 117, 125, 1) !important;
  -moz-box-shadow: 0px 0px 5px 1px rgba(108, 117, 125, 1) !important;
  box-shadow: 0px 0px 5px 1px rgba(108, 117, 125, 1) !important;
}

.responsive-table-scroll {
  overflow-x: auto !important;
}
/* /Table */

/* Navbar */
.navbar {
  background-color: #212529;
}
.nav-link {
  color: #c4e8f9 !important;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-size: 200%;
}
.nav-link.active {
  color: #17a2b8 !important;
}
.nav-link:hover {
  color: #c4bfb9 !important;
}
/* /Navbar */

/* Home */
.home-title {
  font-family: "Montserrat";
}
/* /Home */

/* Pacient */
.pacient-topInfo {
  font-family: "Raleway";
  background-color: rgba(33, 37, 41, 0.3);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68);
  -moz-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68);
  box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68);
}
.normal-font {
  font-family: "Montserrat";
}
.pacient-anamneza-info {
  background-color: rgba(196, 232, 249, 0.3);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.18);
  -moz-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.18);
  box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.18);
}
/* /Pacient */

/* TreatmentForm */
.treatmentForm {
  font-family: "Raleway";
  padding: 15px;
  background-color: rgba(33, 37, 41, 0.1) !important;
  color: #212529 !important;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(33, 37, 41, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(33, 37, 41, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(33, 37, 41, 0.4);
}
.treatment-cover {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68) !important;
  -moz-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68) !important;
  box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68) !important;
}
.treatment-cover-inside {
  border: none !important;
  border-radius: 0 !important;
}
.treatment-header {
  background-color: rgba(196, 232, 249, 0.7) !important;
  border-bottom: rgba(33, 37, 41, 0.1) 1px solid !important;
  border-top: rgba(33, 37, 41, 0.1) 1px solid !important;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68) !important;
  -moz-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68) !important;
  box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.68) !important;
}
.treatment-header div {
  color: #6c757d !important;
}
.treatment-body {
  background-color: rgba(196, 232, 249, 0.3);
}
.conforta-font {
  font-family: "Comfortaa";
}
/* /TreatmentForm */

/* Selection */
.list-group-item.active {
  background-color: rgba(33, 37, 41, 0.95) !important;
  border-color: #212529 !important;
  color: #c4e8f9 !important;
}

.list-group-item:hover {
  background-color: rgba(196, 232, 249, 0.4) !important;
  border-color: #c4e8f9 !important;
  color: #212529 !important;
}

.list-group {
  font-family: "Comfortaa";
  border-radius: 0 !important;
  -webkit-box-shadow: 0px 0px 5px 1px #212529 !important;
  -moz-box-shadow: 0px 0px 5px 1px #212529 !important;
  box-shadow: 0px 0px 5px 1px #212529 !important;
}
/* /Selection */

/* Calendar */
.calendar-cover {
  -webkit-box-shadow: 0px 0px 5px 2px #212529 !important;
  -moz-box-shadow: 0px 0px 5px 2px #212529 !important;
  box-shadow: 0px 0px 5px 2px #212529 !important;
  margin-left: auto;
  margin-right: auto;
}
/* /Calendar */

/* Invoice */
.invoice-box {
  position: relative !important;
  height: 150px !important;
  overflow: auto !important;
  display: block !important;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.5) !important;
  -moz-box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.5) !important;
  box-shadow: 0px 0px 5px 1px rgba(33, 37, 41, 0.5) !important;
}

.invoices-table {
  font-family: "Montserrat" !important;
  font-size: 90%;
}
/* /Invoice */

/* Stats */
.stats-box {
  background-color: rgba(196, 232, 249, 0.25) !important;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(33, 37, 41, 0.5) !important;
  -moz-box-shadow: 0px 0px 3px 1px rgba(33, 37, 41, 0.5) !important;
  box-shadow: 0px 0px 3px 1px rgba(33, 37, 41, 0.5) !important;
}

.stats-box-header {
  padding: 15px;
  margin-bottom: 0 !important;
  border-bottom: 2px solid rgba(33, 37, 41, 0.4);
}

.text-yellow {
  color: #bdb695 !important;
}

.text-purple {
  color: #ae95bd !important;
}

.text-blue {
  color: #6d9399 !important;
}

.btn-mama {
  background-color: rgba(196, 232, 249, 0.3) !important;
  border-color: rgba(196, 232, 249, 0.3) !important;
}

.btn-mama:hover {
  background-color: rgba(196, 232, 249, 0.8) !important;
  border-color: rgba(196, 232, 249, 0.8) !important;
}
/* /Stats */

.img-cover {
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid rgba(196, 232, 249, 0.2);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  width: 250px;
  height: 250px;
  transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out; /* opera */
  -ms-transition: 1s ease-in-out; /* IE 10 */
  -moz-transition: 1s ease-in-out; /* Firefox */
  -webkit-transition: 1s ease-in-out; /*safari and chrome */
}

.img-cover:hover {
  border: 1px solid rgba(196, 232, 249, 0.5);
  background-color: rgba(255, 255, 255, 1);
  width: 300px;
  height: 300px;
  transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out; /* opera */
  -ms-transition: 1s ease-in-out; /* IE 10 */
  -moz-transition: 1s ease-in-out; /* Firefox */
  -webkit-transition: 1s ease-in-out; /*safari and chrome */
}

@media only screen and (max-width: 1024px) {
  .table {
    font-size: 90% !important;
  }

  .stats-box-header {
    font-size: 90% !important;
  }

  .list-group {
    font-size: 70% !important;
  }
}
